import * as signalR from "@microsoft/signalr";
import DEBUG from '@/common/DEBUG';
import { APIURL } from '@/common/constants'

async function initializeGameConnection(token) {
	DEBUG.log('INITIALIZEGAMECONNECTION - using token...')

	const gameConnection = new signalR.HubConnectionBuilder()
		.withUrl(APIURL + "/hubs/game", { accessTokenFactory: () => token })
		.withAutomaticReconnect()
		.configureLogging(signalR.LogLevel.None)
		.build();

	gameConnection.on("Ping", function (message) {
		DEBUG.log('Received - Ping - ', message);
	});

	gameConnection.onclose((error) => {
		DEBUG.log('------------------------------------------- SIGNALR onclose - ', error);
	});

	gameConnection.onreconnecting((error) => {
		DEBUG.log('------------------------------------------- SIGNALR onreconnecting - ', error);
	});

	gameConnection.onreconnected((connectionId) => {
		DEBUG.log('------------------------------------------- SIGNALR reconnected - ', connectionId);
	});

	gameConnection.start().catch(function (err) {
		return console.error('ERROR connecting to server - %o', err.toString());
	});

	//DEBUG.log('gameConnection', gameConnection);
	return gameConnection;
}

export {
	initializeGameConnection,
};